import React, { useEffect, useState } from "react"
// import { StaticImage } from "gatsby-plugin-image"
// import * as styles from "../dashboard/dashboard.module.css"
// import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import "bootstrap/dist/css/bootstrap.css"
// import Navbar from "../../components/Navbar";
// import { useSelector } from "react-redux";
import { Link } from "gatsby"
import Footer from "../../components/Footer"
import Seo from "../../components/seo";
import './homePage.css'
import landingpagebanner from '../../../static/images/newlandingbanner.png'
import homelogo from '../../../static/images/newlandinglogo.png'
import hexagon1 from '../../../static/images/hexagon1.png'
import SkeletonProductLib from '../../components/skeleton/SkeletonProductLib'
import mobile1 from "../../../static/images/mobile1.png"
import mobile2 from "../../../static/images/mobile2.png"
import mobile3 from "../../../static/images/mobile3.png"
import mobile4 from "../../../static/images/mobile4.png"

function HomePage() {

    const [storage, setStorage] = useState(false);
    useEffect(() => {
        const storedData = localStorage.getItem("userInfo");
        if (!storedData) {

            setStorage(false);
        } else {
            setStorage(true);

        }
    }, []);

    const [loader, setLoader] = useState(false);
    useEffect(() => {
        setLoader(true);
        setTimeout(() => { setLoader(false) }, 1000)

    }, []);

    return (

        <div>
            <Seo title="Home" />
            <div className="homemain">
                {loader && <div><SkeletonProductLib /></div>}
                {!loader &&
                    <div className="homepage position-relative homepage" >
                        <img src={landingpagebanner} alt="landingpagebanner" className="w-100 heightImage" />
                        {/* <Link to={"/"}> */}
                        <img src={homelogo} alt="homelogo" className="homelogo" />
                        {/* </Link> */}
                        <h4 className="estimateText">WELCOME TO THE REAL ESTATE REVOLUTION</h4>
                    </div>
                }
            </div>
            <div className="bannnerBottomText">
                <p className="landingText">The First and Only End-to-End Solution Developed </p>
                <p className="landingText">Exclusively For the Real Estate Industry.</p>
            </div>
            <div className="row align-items-center m-0 mt-3">
                <div className="col-md-3 order2">
                    <h5 className="phoneText simpText">Simple</h5>
                </div>
                <div className="col-md-3">
                    <img src={mobile1} alt="landingphone1" className="landingphone1" />
                </div>
                <div className="col-md-3 order2">
                    <img src={mobile2} alt="landingphone1" className="landingphone1" />
                </div>
                <div className="col-md-3 order1">
                    <h5 className="phoneText compText">Complete</h5>
                </div>
            </div>
            <div className="row align-items-center m-0 mt-3 mb-5">
                <div className="col-md-3 order2 landingpagemobile">
                    <h5 className="phoneText simpText">Engaging</h5>
                </div>
                <div className="col-md-3">
                    <img src={mobile3} alt="landingpagephone2" className="landingphone1" />
                </div>
                <div className="col-md-3 order2">
                    <img src={mobile4} alt="landingphone1" className="landingphone1" />
                </div>
                <div className="col-md-3 order1">
                    <h5 className="phoneText compText">Revolutionary</h5>
                </div>
            </div>
            <div className=" position-relative w-100">
                <img src={hexagon1} alt="hexagon" className="hexagon" />
                <div className="hexagonInner">
                    <h2 className="hexagonInnerText1">Join the Broker Metaverse</h2>
                    <div className="hexagonContain">
                        <h2 className="hexagonInnerText2">REDEFINE WHAT</h2>
                        <h2 className="hexagonInnerText2">REAL ESTATE MEANS</h2>
                    </div>
                </div>
            </div>
            <div className="needhome">
                <h3 className="homeText"><span style={{color:"#101d49"}}>Join the Real Estate Revolution and Go</span> <span className="keyText">Key
                </span><span className="weText" style={{color:"#80bd01",}}>We</span></h3>
                <h6 className="homeemail">To learn more send us an email at <Link to="" style={{ color: "#0490fb", textDecoration: "none" }}>info@gokeywe.com</Link></h6>
            </div>
            <Footer isLogin={storage} />
        </div>
    )
}
export default HomePage
